import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import store from './store'

window.axios = require('axios')

import VueElementLoading from 'vue-element-loading'
Vue.component('VueElementLoading', VueElementLoading)

//SERVER
Vue.prototype.mediaUURL = "https://api.myqpad.com/"
axios.defaults.baseURL = "https://api.myqpad.com/";
Vue.prototype.mediaURL = "https://api.myqpad.com/file/get/";
Vue.prototype.ipURL = "https://api.myqpad.com/"

//LOCAL
// Vue.prototype.mediaUURL = "http://192.168.1.5:4000/"
// axios.defaults.baseURL = "http://192.168.1.5:4000/";
// Vue.prototype.mediaURL = "http://192.168.1.5:4000//file/get/";
// Vue.prototype.ipURL = "http://192.168.1.5:4000/"

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
